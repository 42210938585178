<template>
  <div class="host">
    <el-pagination style="padding: 10px 0 5px 35px" v-if="total >= 9" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[9, 12, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
    <div class="box" v-loading="loading">

      <el-row :gutter="15">
        <el-col :xs="24" :md="12" :lg="8"  v-for="item in dataSource" :key="item.hostId">
            <el-card class="box-card" >
        <div slot="header">
          <h2 class="title">
            <span>{{ item.company.companyName }}</span>
            <el-button class="" @click="goto(item.pkHostId, 'task')">任务</el-button>
            <el-button class="" @click="goto(item.pkHostId, 'node')">节点</el-button>
            <el-button class="" @click="showDialog(item)" v-permission="3">修改</el-button>
          </h2>
        </div>
        <div class="text item"><span class="label">主 机 编 号: </span> {{ item.hostNumber }}</div>
        <div class="text item"><span class="label">设 备 型 号: </span> {{ item.equipmentModel }}</div>
        <div class="text item"><span class="label">主机管理员: </span> {{ item.user.userName }}</div>
        <div class="text item"><span class="label">测 试 精 度: </span> {{ item.precision || 0.01 }}</div>
        <div class="text item"><span class="label">生 产 厂 家: </span> {{ item.equipmentManufacturer }}</div>
      </el-card>
       </el-col>
        </el-row>
       
      
    </div>

    <el-dialog class="hostDialog" center="" title="修改主机用户" :visible.sync="dialogVisibleHost" width="500px" :before-close="handleClose">
      <hostDialog :hostId="hostId" :unitId="unitId" v-if="dialogVisibleHost"></hostDialog>
    </el-dialog>
  </div>
</template>

<script>
import hostDialog from './HostDialog.vue'
import { findHostByUnit, findAllHost } from '@/api/Host.js'

const dataSource = []
export default {
  components: { hostDialog },
  data() {
    return {
      dataSource,
      dialogVisibleHost: false,
      currentPage: 0,
      pageSize: 9,
      total: null,
      unitId: null,
      loading: true,
    }
  },
  methods: {
    async findAllHostFun() {
      this.loading = true
      console.log(this.$store.state.power)
      const { data: res } = this.$store.state.power === 4 ? await findAllHost(this.currentPage, this.pageSize) : await findHostByUnit(this.$store.state.unitId, this.currentPage, this.pageSize)
      console.log(res.data.list)
      this.dataSource = res.data.list
      this.total = res.data.total
      setTimeout(() => {
        this.loading = false
      }, 200)
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.findAllHostFun()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.findAllHostFun()
    },
    showDialog(e) {
      console.log('eeeeeeeeeeeeee', e)
      this.hostId = e.pkHostId
      this.unitId = e.fkCompanyId
      this.dialogVisibleHost = true
    },
    goto(hostId, type) {
      this.$router.push({
        name: type === 'task' ? 'TaskManagerJob' : 'UnitManagerNode',
        params: {
          hostId,
        },
      })
    },
    handleClose() {
      this.dialogVisibleHost = false
    },
  },
  created() {
    this.findAllHostFun()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.host {
  // &::hover {
  //   display: block;
  //   clear: both;
  //   content: '';
  // }
  // padding: 10px;
  width: 100%;
  overflow: hidden;
  .box {
     
    // width: calc(100% - 35px);
    padding:15px 15px;
  }
  .hostDialog ::v-deep .el-dialog {
    height: 370px;
  }
  .box-card {
    // width: 600px;
    // margin: 10px 20px 20px 0;
    // float: left;
    // position: relative;
    margin-bottom:15px;
    transition: all 0.3s ease;
    cursor: pointer;
    // bottom: 0;
    box-shadow: 0px 0px 10px #b9b9b9;
    &:hover {
      bottom: 10px;
      box-shadow: 0px 6px 12px #d4d4d4;
    }
    .el-card__header {
      .title {
        display: flex;
        span {
          line-height: 40px;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis; /* 超出部分显示省略号 */
          white-space: nowrap; /*规定段落中的文本不进行换行 */
        }
      }
    }
    .label {
      display: inline-block;
      width: 84px;
      text-align: right;
    }

    .item {
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis; /* 超出部分显示省略号 */
      white-space: nowrap; /*规定段落中的文本不进行换行 */
    }
    .text {
      line-height: 28px;
      height: 28px;
      font-size: 16px;
      color: #333;
    }
  }
}
</style>
