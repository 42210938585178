<template>
  <div class="unit">
    <el-form ref="ruleForm" :model="form" label-width="120px">
      <el-form-item label="主机编号">
        <el-input v-model="form.hostNumber" :disabled="true"></el-input>
      </el-form-item>

      <el-form-item label="当前管理员">
        <el-select v-model="form.fkHostUserId" clearable placeholder="请选择" no-data-text>
          <el-option v-for="item in hostAdminList" :key="item.value" :label="item.userName" :value="item.pkUserId"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="可视用户">
        <el-select v-model="userIds" multiple clearable placeholder="请选择">
          <el-option v-for="item in userList" :key="item.pkUserId" :label="item.userName" :value="item.pkUserId"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="updateHostInfoFun">立即修改</el-button>
        <el-button @click="onCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { findUserByHost, updateHostUser, updateHostHostUser, findHostById } from '@/api/Host'
import { findUserListByUnit } from '@/api/User'
export default {
  data() {
    return {
      form: {
        fkCompanyId: '',
        equipmentModel: 'HNGZM-01',
        fkHostUserId: null,
      },

      unitList: [],
      // 勾选框

      userIds: [],
      // userList: [],
      userList: [],
      hostAdminList: [],
    }
  },
  props: {
    hostId: {
      type: Number,
    },
    unitId: {
      type: Number,
    },
    // 主机管理员
  },
  methods: {
    async updateHostInfoFun() {

      await this.updateHostUserFun()
      await this.updateHostHostUserFun()

      this.form = null
      await this.$parent.handleClose()
      await this.$parent.$parent.findAllHostFun()
    },
    // 修改  修改主机管理员
    async updateHostHostUserFun() {
      const { data: res } = await updateHostHostUser(this.hostId, this.form.fkHostUserId ? this.form.fkHostUserId - 0 : 0)
      this.$message({
        message: '主机管理员' + res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
    },
    //  修改主机的可视用户
    async updateHostUserFun() {
      const userIds = this.userIds.length === 0 ? 0 : this.userIds.join()
      const { data: res } = await updateHostUser(this.hostId, userIds)
      this.$message({
        message: '用户重新' + res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
    },

    // 根据主机Id查询主机信息
    async findHostByIdFun() {
      const { data: res } = await findHostById(this.hostId)

      this.form = res.data.list[0]
      this.unitId = res.data.list[0].fkCompanyId
      this.form.fkHostUserId = this.form.fkHostUserId === 0 ? null : this.form.fkHostUserId

    },

    async findUserByUnitFun() {
      const { data: res } = await findUserListByUnit(this.unitId)

      res.data.list.forEach((item) => {
        if (item.fkJurisdictionId === 1) {
          this.userList.push(item)
        } else if (item.fkJurisdictionId === 2) {
          this.hostAdminList.push(item)
        }
      })
    },
    async findUserByHostFun() {
      const { data: res } = await findUserByHost(this.hostId)

      const userIds = []
      res.data.forEach((item) => {
        // userIds.push(item.fkHostUserId)
        userIds.push(item.fkUserId)
      })

      this.userIds = userIds
    },

    onCancel() {
      this.$message('取消修改主机')
      this.$parent.handleClose()
    },
  },
  created() {
    this.findHostByIdFun()
    this.findUserByUnitFun()
    this.findUserByHostFun()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.el-form {
  .el-input,
  .el-select {
    width: 300px;
  }
}
</style>
